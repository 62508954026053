import React, {useEffect, useState} from 'react';
import {setJobDescriptionMode} from "../../app/reducers/raet/raetReducer";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";
import PWButton from "../../shared/ui/btn/PhonewiseButton";
import {useDispatch, useSelector} from "react-redux";
import {
    setMastogramInstancesData,
    setMastogramMainTableMode,
    setMastogramSelectedGroup
} from "../../app/reducers/mastogram/mastogramReducer";
import {RootState} from "../../app/reducers";
import {behindAPI} from "../../app";
import Alert from '@mui/material/Alert';
import 'flowbite';
import { Dropdown } from "flowbite-react";
import { Modal } from "flowbite-react";

const MastogramBlueskyCreateEdit = () => {
    const dispatch = useDispatch();
    const selectedInstance = useSelector((state: RootState) => state.mastogram.instanceData);
    const mode = useSelector((state: RootState) => state.mastogram.mainTableMode);
    const [openModal, setOpenModal] = useState(false);
    const [instanceData, setInstanceData] = useState({
        instance_handle: "",
        instance_uuid: "",
        instance_enabled: false,
        instance_post: false,
        instance_forward: false,
        instance_fw_reblogs: false,
        instance_fw_bookmarks: false,
        instance_post_privacy: "public",
        session_created: null,
        prompt_id: null
    });

    useEffect(() => {
        // @ts-ignore
        if (window.Flowbite) {
            // @ts-ignore
            window.Flowbite.init();
        }
    }, []);

    useEffect(() => {
        if (mode !== "create") {
            setInstanceData({
                ...instanceData,
                instance_handle: selectedInstance.instance_handle || "",
                instance_uuid: selectedInstance.instance_uuid || "",
                instance_enabled: selectedInstance.instance_enabled || false,
                instance_post: selectedInstance.instance_post || false,
                instance_forward: selectedInstance.instance_forward || false,
                instance_fw_reblogs: selectedInstance.instance_fw_reblogs || false,
                instance_fw_bookmarks: selectedInstance.instance_fw_bookmarks || false,
                instance_post_privacy: selectedInstance.instance_post_privacy || "public",
                session_created: selectedInstance.session_created || null,
                prompt_id: selectedInstance.prompt_id || null
            });
        } else {
            setInstanceData({
                ...instanceData,
                instance_handle: "",
                instance_uuid: "",
                instance_enabled: false,
                instance_post: false,
                instance_forward: false,
                instance_fw_reblogs: false,
                instance_fw_bookmarks: false,
                instance_post_privacy: "public",
                prompt_id: null,
                session_created:null
            });
        }
    }, [selectedInstance]);

    const updateInstanceData = async (id:string) => {
        const res = await behindAPI.MastogramBlueskyUpdate(id,
            instanceData.instance_enabled, instanceData.instance_post, instanceData.instance_forward,
            instanceData.instance_fw_reblogs, instanceData.instance_fw_bookmarks, instanceData.instance_post_privacy, instanceData.prompt_id);
        if (res.success) {
            dispatch(setMastogramMainTableMode('overview'));
            const res = await behindAPI.MastogramBlueskyGet();
            dispatch(setMastogramInstancesData(res?.data))
        }
    }

    const onMastogramInstanceSave = async () => {
        const res = await behindAPI.MastogramBlueskyUpdate(instanceData.instance_uuid,
            instanceData.instance_enabled, instanceData.instance_post, instanceData.instance_forward,
            instanceData.instance_fw_reblogs, instanceData.instance_fw_bookmarks, instanceData.instance_post_privacy, instanceData.prompt_id);
        if (res.success) {
            dispatch(setMastogramMainTableMode('overview'));
            const res = await behindAPI.MastogramBlueskyGet();
            dispatch(setMastogramInstancesData(res?.data))
        }
    }

    const onContinue = async (handle:string) => {
        const res = await behindAPI.MastogramBlueskyCreate(handle);
        if (res.success) {
            setInstanceData({...instanceData, instance_uuid: res.data})
        }
    }

    const onDelete = async () => {
        const res = await behindAPI.MastogramBlueskyDelete(instanceData.instance_uuid);
        if (res.success) {
            setOpenModal(false);
            dispatch(setMastogramMainTableMode('overview'));
            const res = await behindAPI.MastogramBlueskyGet();
            dispatch(setMastogramInstancesData(res?.data))
        }
    }

    return (
        <div className="max-w-full mx-auto p-4">
            {/*{JSON.stringify(instanceData)}*/}
            <div className='flex items-center justify-between mb-6'>
                <div className='flex items-center'>
                    <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 mr-4 cursor-pointer max-w-9 max-h-9'
                         onClick={() => dispatch(setMastogramMainTableMode('overview'))}
                    >
                        <ArrowUturnLeftIcon style={{width: 20}}/>
                    </div>
                    <div className='text-xl font-semibold'>
                        Edit profile
                    </div>
                </div>
                <div>
                    {instanceData.instance_uuid.length > 0 &&
                        <PWButton onClick={() => onMastogramInstanceSave()}>
                            Save
                        </PWButton>
                    }

                </div>
            </div>
            {instanceData?.instance_uuid.length > 0 ?
                (
                    <div className="flex items-center mb-4">
                        {/*@ts-ignore*/}
                        {/*<img src={`${instanceData?.mastodon_account?.avatar ? instanceData?.mastodon_account?.avatar : "https://via.placeholder.com/50"}`}*/}
                        {/*     alt="Profile"*/}
                        {/*     className="w-12 h-12 rounded-xl"*/}
                        {/*/>*/}
                        {/*<div className="ml-4">*/}
                        {/*    <p className="font-medium text-gray-700">{instanceData?.mastodon_account?.display_name}</p>*/}
                        {/*    <p className="text-sm text-gray-500">@{instanceData?.mastodon_account?.username}</p>*/}
                        {/*</div>*/}
                    </div>
                )
                :
                (
                    <div>
                        <div>
                            {/*<Alert severity="info">Watch the <a href="https://youtu.be/z-uB-s4Jj8g">instruction video</a> first</Alert>*/}
                            {/*<br/>*/}
                            <Alert severity="info">Please provide your Bluesky handle below. This is your unique username on the Bluesky social platform, usually formatted like an email address (e.g., username.bsky.social). You can locate it in your Bluesky profile, either in your account settings or at the top of your profile page.</Alert>
                        </div>
                    </div>
                )

            }
            <div className="mb-4">
                <label htmlFor="domain" className="block font-medium text-gray-700">
                    Handle
                </label>
                <input
                    type="text"
                    id="domain"
                    value={instanceData.instance_handle}
                    disabled={instanceData.instance_uuid.length > 0}
                    onChange={(e) => setInstanceData({ ...instanceData, instance_handle: e.target.value })}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-200"
                />
            </div>
            {instanceData.instance_uuid.length === 0 &&
                <div>
                    <PWButton
                        onClick={() => onContinue(instanceData.instance_handle)}
                        disabled={
                            !/^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/.test(instanceData.instance_handle)
                        }
                    >
                        Continue
                    </PWButton>
                </div>
            }
            {instanceData.instance_uuid.length > 0 &&
                <>
                {instanceData.session_created === null &&
                    <div>
                        <Alert severity="info">
                            Authorize Your Bluesky Profile.
                            To proceed, please click the "Authorize" button below.
                            You’ll be redirected to the official Bluesky OAuth page, where you'll need to enter your Bluesky handle and password.
                            Once authorization is complete, you'll be seamlessly returned to the Mastogram website.
                        </Alert>
                        <div className="flex items-center mb-4 justify-between">
                            <label
                                htmlFor="instanceEnabled"
                                className="mr-4 font-medium text-gray-700"
                            >
                                Authorization
                            </label>

                            <PWButton
                                type={'secondary'}
                                onClick={() => {
                                    window.open(`https://app.mastogr.am/bluesky-oauth/login?handle=${instanceData.instance_handle}`, '_blank');
                                }}>Authorize
                            </PWButton>


                        </div>
                    </div>

                }
                {instanceData.session_created !== null &&
                <div>
                    <div className="flex items-center mb-4">
                        <label
                            htmlFor="instanceEnabled"
                            className="mr-4 font-medium text-gray-700"
                        >
                            Profile enabled
                        </label>
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={instanceData.instance_enabled}
                                onChange={() => setInstanceData({
                                    ...instanceData,
                                    instance_enabled: !instanceData.instance_enabled // Toggle instance_enabled on change
                                })}
                                className="sr-only peer"
                            />
                            <div
                                className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-violet-300 dark:peer-focus:ring-violet-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet-600"
                            ></div>
                        </label>
                    </div>

                    <div className="mb-4">
                        <h3 className="font-semibold text-gray-700">Bridge options</h3>
                        <div className="mt-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    checked={instanceData.instance_post}
                                    onChange={() => setInstanceData({
                                        ...instanceData,
                                        instance_post: !instanceData.instance_post
                                    })}
                                    className="form-checkbox h-5 w-5 text-violet-600 bg-violet-500"
                                />
                                <span className="ml-2 text-gray-700">(Write) Allow Posting to This Profile</span>
                            </label>
                        </div>
                        <div className="mt-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    checked={instanceData.instance_forward}
                                    onChange={() => setInstanceData({
                                        ...instanceData,
                                        instance_forward: !instanceData.instance_forward
                                    })}
                                    className="form-checkbox h-5 w-5 text-violet-600"
                                />
                                <span className="ml-2 text-gray-700">(Read) Auto-Forward from This Profile</span>
                            </label>
                        </div>
                        <div className="mt-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    checked={instanceData.instance_fw_reblogs}
                                    onChange={() => setInstanceData({
                                        ...instanceData,
                                        instance_fw_reblogs: !instanceData.instance_fw_reblogs
                                    })}
                                    className="form-checkbox h-5 w-5 text-violet-600"
                                />
                                <span className="ml-2 text-gray-700">Forward reposts</span>
                            </label>
                        </div>
                        <div className="mt-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    checked={instanceData.instance_fw_bookmarks}
                                    onChange={() => setInstanceData({
                                        ...instanceData,
                                        instance_fw_bookmarks: !instanceData.instance_fw_bookmarks
                                    })}
                                    className="form-checkbox h-5 w-5 text-violet-600"
                                />
                                <span className="ml-2 text-gray-700">Forward bookmarks</span>
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="privacy" className="block font-medium text-gray-700">
                            New posts privacy
                        </label>
                        <select
                            id="privacy"
                            value={instanceData.instance_post_privacy}
                            onChange={(e) => setInstanceData({
                                ...instanceData,
                                instance_post_privacy: e.target.value
                            })}
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                            <option value="unlisted">Unlisted</option>
                        </select>
                    </div>
                </div>
                }

                </>
            }
            {instanceData.instance_uuid.length > 0 &&
                <div className={'flex justify-between items-center'}>
                    <div className={'text-black/40 text-sm'}>
                        <i>Channel ID: {instanceData.instance_uuid}</i>
                    </div>
                    <div>
                        <Dropdown label="Dropdown button"
                                  renderTrigger={() => <span className={'bg-gray-200 text-gray-800 hover:bg-gray-300 py-2 px-3 max-w-9 flex justify-center items-center rounded-xl cursor-pointer'}>...</span>}
                                  className={'rounded-xl'}
                                  dismissOnClick={false} >
                            <Dropdown.Item onClick={() => setOpenModal(true)}>Delete</Dropdown.Item>
                        </Dropdown>
                        <Modal show={openModal} onClose={() => setOpenModal(false)}>
                            <Modal.Header>Delete profile</Modal.Header>
                            <Modal.Body>
                                <div className="space-y-6">
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                        Are you sure you want to delete this profile?
                                    </p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <PWButton type={'alert'} onClick={() => onDelete()}>Yes</PWButton>
                                <PWButton type={'secondary'} onClick={() => setOpenModal(false)}>Cancel</PWButton>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            }


        </div>
    );
};

export default MastogramBlueskyCreateEdit;