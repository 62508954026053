import React from 'react';
import './PhonewiseButton.scss'
import { useNavigate } from 'react-router-dom';

interface PWButtonProps {
    id?: string;
    type?: 'primary' | 'secondary' | 'alert';
    size?: 'normal' | 'small' | 'large' | 'square';
    loading?: boolean;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    navigateTo?: string;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
}

const PWButton: React.FC<PWButtonProps> = ({
                                               type = 'primary',
                                               size = 'normal',
                                               loading = false,
                                               onClick,
                                               navigateTo,
                                               children,
                                               className, // Destructure className from props
                                               disabled = false,   // Destructure disabled from props
                                           }) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(e);
        } else if (navigateTo) {
            navigate(navigateTo);
        }
    };

    return (
        <button
            className={`
                    flex
                    justify-center
                    items-center
                    rounded-xl
                    cursor-pointer
                    ${type === 'primary' ? 'bg-violet-800 text-white hover:bg-violet-700'
                            : type === 'secondary' ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                                : type === 'alert' ? 'bg-red-600 text-red-100 hover:bg-red-700'
                                    : 'bg-violet-800 text-white'}
                    ${size === 'normal' ? 'py-2 px-6'
                            : size === 'small' ? 'py-1 px-2'
                                : size === 'large' ? 'py-3 px-6'
                                    : size === 'square' ? 'py-2 px-3' : 'py-2 px-6'}
                    ${disabled ? 'opacity-50 cursor-not-allowed hover:bg-transparent' : ''}
                    ${className} // Apply className to the button
                `}
            onClick={handleClick}
            disabled={loading || disabled}
        >
            {loading ? <Loader color={type === 'primary' ? 'light' : 'dark'} /> : children}
        </button>
    );
};

export default PWButton;

interface LoaderProps {
    color?: 'light' | 'dark';
}
const Loader: React.FC<LoaderProps> = ({color= 'dark'}) => {
    return (
        <div className={`loader__container ${color === 'light' ? 'loader__container--light' : 'loader__container--dark'}`}/>
    );
};